<template>
    <v-sheet 
        :width="width"
        :height="height"
        style="position:relative; cursor:pointer;"
        @click="goToLink()"
    >
        <v-img
            :width="width"
            :height="height"
            :src="'/upload/banner/'+encodeURI(banner_image)"
        >
            <!-- Mobile -->
            <v-sheet
                v-if="$vuetify.breakpoint.mobile"
                class="mx-auto"
                style="position:absolute; left:12px; bottom:12px;"
                color="transparent"
            >
                <p class="text-subtitle-1 font-weight-bold white--text mb-2">
                    {{title}}
                </p>
                <p class="text-body-2 font-weight-bold white--text mb-0" style="line-height:16px;">
                    {{subtitle}}
                </p>
            </v-sheet>

            <!-- PC -->
            <v-sheet
                v-if="!$vuetify.breakpoint.mobile"
                width="1200"
                class="mx-auto"
                style="padding-top:120px; padding-left:30px;"
                color="transparent"
            >
                <p class="text-h5 font-weight-bold white--text mb-2">
                    {{title}}
                </p>
                <p class="text-h6 font-weight-bold white--text" style="line-height:50px;">
                    {{subtitle}}
                </p>
            </v-sheet>
        </v-img>
    </v-sheet>
</template>
<script>
export default {
    props: ["type", "width", "height", "title", "subtitle"],

    data: () => ({
        banner_image: "",
        link: ""
    }),

    created(){
        this.$http.post('/api/admin/banner/import', {
            params: {
                type: this.type,
            }
        }).then((res) => {
            this.banner_image = res.data[0].banner_image
            this.link = res.data[0].link
        })
    },

    methods: {
        goToLink(){
            window.open(this.link, '_blank')
        }
    }
}
</script>