<template>
    <node-view-wrapper
        as="div"
        class="image-container d-flex"
        :class="node.attrs.align"
    >
        <!-- 작성 -->
        <div
            v-if="$route.query.type == 'write' || $route.query.type == 'update'"
            style="width:480px; background:#e8e9ed;"
            class="pa-4 rounded-10"
        >
            <v-text-field
                placeholder="투표 제목을 입력해주세요"
                outlined
                class="rounded-10 mb-2"
                style="background:white;"
                hide-details
                dense
                v-model="title"
            ></v-text-field>
            <v-text-field
                placeholder="투표 마감일을 입력해주세요"
                outlined
                class="rounded-10"
                style="background:white;"
                hide-details
                dense
                type="datetime-local"
                v-model="deadline"
            ></v-text-field>

            <v-divider class="mt-3 mb-3" style="background:#ffffff; border:1px solid #ffffff;"></v-divider>

            <div
                v-for="(option, index) in option_list" :key="index"
                class="d-flex align-center mb-2"
            >
                <v-text-field
                    placeholder="보기를 입력해주세요"
                    outlined
                    class="rounded-10"
                    style="background:white;"
                    hide-details
                    dense
                    v-model="option.title"
                ></v-text-field>
                <v-btn
                    small
                    height="40"
                    width="40"
                    class="rounded-10 ml-2"
                    depressed
                    color="#7e829f"
                    dark
                    @click="subtractOption(index)"
                >
                    <v-icon>mdi-minus</v-icon>
                </v-btn>
            </div>
            <div
                class="mb-2"
            >
                <v-btn
                    depressed
                    class="rounded-10"
                    width="100%"
                    color="#7e829f"
                    dark
                    @click="addOption()"
                >
                    <v-icon>mdi-plus</v-icon>
                    보기 추가
                </v-btn>
            </div>
            <v-btn
                depressed
                class="rounded-10"
                width="100%"
                color="#7e829f"
                dark
                @click="submit()"
            >
                투표 작성 적용하기
            </v-btn>
        </div>

        <!-- 읽기 -->
        <div
            v-if="$route.query.type == 'read'"
            style="width:480px; background:#e8e9ed;"
            class="pa-4 rounded-10"
        >
            <div
                class="d-flex align-center px-2"
            >
                <v-icon color="blue" size="36">mdi-vote-outline</v-icon>
                <p
                    class="mx-3 mb-0"
                >
                    <span class="text-subtitle-1" style="line-height:16px !important;">{{title}}</span><br/>
                    <span class="grey--text" style="font-size:10px;">투표 마감일 : {{new Date(deadline).toLocaleString()}}</span>
                    <span class="blue--text ml-6" style="font-size:10px;"><v-icon x-small class="mr-1">mdi-account</v-icon>{{sumResultCount(result_list)}}명 참여</span>
                </p>
            </div>

            <v-divider class="mt-3 mb-3" style="background:#ffffff; border:1px solid #ffffff;"></v-divider>

            <v-radio-group
                v-if="view == 'option'"
                v-model="selected_option"
                column
            >
                <div
                    v-for="(option, index) in option_list" :key="index"
                    class="d-flex align-center mb-2 px-3 ml-2px"
                >
                    <v-radio
                        dense
                        hide-details
                        :value="option"
                    ></v-radio>
                    <p
                        class="mb-2"
                    >
                        {{option.title}}
                    </p>
                </div>
            </v-radio-group>

            <div
                v-if="view == 'result'"
            >
                <div
                    v-for="(option, index) in result_list" :key="index"
                    class="mt-1 mb-3 px-3 ml-2px"
                >
                    <div
                        class="d-flex align-center"
                    >
                        <v-radio
                            class="mt-1"
                            dense
                            hide-details
                        ></v-radio>
                        <p
                            class="mb-1"
                        >
                            {{option.title}}<br/>
                            <span class="orange--text text-caption">{{calculateProbability(option.result_count, (sumResultCount(result_list)))}}% ({{option.result_count}}명)</span>
                        </p>
                    </div>
                    <div
                        style="width:200px; overflow:hidden;"
                        class="ml-8"
                    >
                        <v-progress-linear
                            :value="calculateProbability(option.result_count, (sumResultCount(result_list)))"
                        ></v-progress-linear>
                    </div>
                </div>
            </div>
            <div
                class="d-flex"
            >
                <v-btn
                    v-if="view != 'result'"
                    depressed
                    class="rounded-10 flex-grow-1"
                    color="#7e829f"
                    dark
                    @click="vote_submit()"
                >
                    투표하기
                </v-btn>
                <v-btn
                    v-if="view == 'result'"
                    depressed
                    class="rounded-10 flex-grow-1"
                    color="#7e829f"
                    dark
                    @click="view = 'option'"
                >
                    투표보기
                </v-btn>
                <v-btn
                    depressed
                    class="rounded-10 flex-grow-1 ml-2"
                    color="#7e829f"
                    dark
                    @click="view = 'result'"
                >
                    결과보기
                </v-btn>
            </div>
        </div>
    </node-view-wrapper>
</template>
<script>
import { NodeViewWrapper, nodeViewProps } from '@tiptap/vue-2'
export default {
    components: {
        NodeViewWrapper
    },

    props: nodeViewProps,

    data: () => ({
        title: "",
        deadline: null,
        option_list: [
            {
                title: ""
            }
        ],

        result_list: [],

        selected_option: {},
        view: "option"
    }),

    mounted(){
        console.log(this.$route.query.type)
        console.log(this.node.attrs.id)

        this.load()
    },

    methods: {
        load(){
            this.$http.post("/api/vote/select", {
                params: {
                    id: this.node.attrs.id
                }
            }).then((res) => {
                console.log(res)
                this.title = res.data[0].title
                this.deadline = res.data[0].deadline
            })

            this.$http.post("/api/vote/select/option", {
                params: {
                    vote_id: this.node.attrs.id
                }
            }).then((res) => {
                this.option_list = res.data
            })
            
            this.$http.post("/api/vote/select/result", {
                params: {
                    vote_id: this.node.attrs.id
                }
            }).then((res) => {
                console.log(res)
                this.result_list = res.data
            })
        },

        sumResultCount(resultList) {
            return resultList.reduce((sum, item) => sum + (item.result_count || 0), 0);
        },

        subtractOption(index){
            this.option_list.splice(index, 1)
        },

        addOption(){
            this.option_list.push({
                title: ""
            })
        },

        submit(){
            this.$http.post("/api/vote/insert", {
                params: {
                    id: this.node.attrs.id,
                    title: this.title,
                    deadline: this.deadline,
                }
            }).then((res) => {
                for(let option of this.option_list){
                    this.$http.post("/api/vote/insert/option", {
                        params: {
                            vote_id: this.node.attrs.id,
                            title: option.title
                        }
                    })
                }

                if(res.data.affectedRows){
                    alert("투표 작성이 적용되었습니다.")
                }
            })
        },

        vote_submit(){
            console.log(this.selected_option)
            this.$http.post("/api/vote/insert/result", {
                params: {
                    user_id: this.$store.state.hyundai_user.user_id,
                    vote_id: this.node.attrs.id,
                    vote_option_id: this.selected_option.id,
                    title: this.selected_option.title
                }
            }).then((res) => {
                if(res.data.affectedRows){
                    this.load()
                    this.view = "result"
                }
            })
        },

        // 수집률 계산
        calculateProbability(count, totalNumber) {
            // 숫자가 아니거나 분모가 0일 경우
            if (isNaN(count) || isNaN(totalNumber) || parseInt(totalNumber) === 0) {
                return 0
            }

            // 백분율 처리
            const probability = (count / totalNumber) * 100
            return parseInt(probability)
        }
    },
}
</script>